exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-my-profile-index-tsx": () => import("./../../../src/pages/admin/my-profile/index.tsx" /* webpackChunkName: "component---src-pages-admin-my-profile-index-tsx" */),
  "component---src-pages-admin-my-profile-invoices-index-tsx": () => import("./../../../src/pages/admin/my-profile/invoices/index.tsx" /* webpackChunkName: "component---src-pages-admin-my-profile-invoices-index-tsx" */),
  "component---src-pages-admin-my-profile-notifications-index-tsx": () => import("./../../../src/pages/admin/my-profile/notifications/index.tsx" /* webpackChunkName: "component---src-pages-admin-my-profile-notifications-index-tsx" */),
  "component---src-pages-admin-my-shop-index-tsx": () => import("./../../../src/pages/admin/my-shop/index.tsx" /* webpackChunkName: "component---src-pages-admin-my-shop-index-tsx" */),
  "component---src-pages-admin-quotes-tsx": () => import("./../../../src/pages/admin/quotes/[...].tsx" /* webpackChunkName: "component---src-pages-admin-quotes-tsx" */),
  "component---src-pages-admin-schedule-index-tsx": () => import("./../../../src/pages/admin/schedule/index.tsx" /* webpackChunkName: "component---src-pages-admin-schedule-index-tsx" */),
  "component---src-pages-admin-staff-tsx": () => import("./../../../src/pages/admin/staff/[...].tsx" /* webpackChunkName: "component---src-pages-admin-staff-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-out-tsx": () => import("./../../../src/pages/sign-out.tsx" /* webpackChunkName: "component---src-pages-sign-out-tsx" */),
  "component---src-pages-sign-up-[token]-index-tsx": () => import("./../../../src/pages/sign-up/[token]/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-[token]-index-tsx" */)
}


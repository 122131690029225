// src/theme.ts
import { DEFAULT_THEME, createTheme, mergeMantineTheme } from '@mantine/core';

const _theme = createTheme({
  autoContrast: true,
  breakpoints: {
    lg: '64em',
    md: '52.125em',
    sm: '48em',
    xl: '90em',
    xs: '30em'
  },
  fontFamily: 'Helvetica',
  primaryColor: 'blue'
  // ... other theme override properties
});

export const theme = mergeMantineTheme(DEFAULT_THEME, _theme);

import { formatDate } from '@/components/utils';
import {
  GraphqlWSSubProtocol,
  createSubscriptionClient
} from '@chillworks/graphql-ws';
import { notifications } from '@mantine/notifications';
import { EntityId, EntityState } from '@reduxjs/toolkit';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  customerAdapter,
  profileAdapter,
  shopAddressAdapter,
  shopAddressServiceAdapter
} from '../store/entity-adapters';
import {
  CalendarEventBookingState,
  CalendarEventType,
  Customer,
  GetAllMyCustomersDocument,
  GetAllMyCustomersQuery,
  GetAllMyEmployeesDocument,
  GetAllMyEmployeesQuery,
  GetAllMyShopAddressServicesDocument,
  GetAllMyShopAddressServicesQuery,
  GetAllMyShopAddressesDocument,
  GetAllMyShopAddressesQuery,
  OnMyCalendarEventUpdatedDocument,
  OnMyCalendarEventUpdatedSubscription,
  OnMyCalendarEventUpdatedSubscriptionVariables,
  OnProfileUpdatedByIdDocument,
  OnProfileUpdatedByIdSubscription,
  OnProfileUpdatedByIdSubscriptionVariables,
  OnQuoteUpdatedDocument,
  OnQuoteUpdatedSubscription,
  OnQuoteUpdatedSubscriptionVariables,
  Profile,
  ShopAddress,
  ShopAddressService,
  api as generatedApi
} from './generated';
import {
  ADDRESSES,
  BOOKINGS,
  CALENDAR_EVENTS,
  CUSTOMERS,
  EMPLOYEES,
  INVITES,
  ME,
  MY_PROFILE_DETAILS,
  NOTIFICATIONS,
  QUOTE,
  QUOTE_TEMPLATES,
  SERVICES,
  SHOP
} from './tags';

export const api = generatedApi
  .injectEndpoints({
    endpoints: (build) => ({
      GetAllMyCustomers: build.query<
        EntityState<Partial<Customer>, EntityId>,
        void
      >({
        query: (variables) => ({
          document: GetAllMyCustomersDocument,
          variables
        }),
        transformResponse: (response: GetAllMyCustomersQuery) =>
          customerAdapter.addMany(
            customerAdapter.getInitialState(),
            response.myShop?.customers ?? []
          )
      }),
      GetAllMyEmployees: build.query<
        EntityState<Partial<Profile>, EntityId>,
        void
      >({
        query: (variables) => ({
          document: GetAllMyEmployeesDocument,
          variables
        }),
        transformResponse: (response: GetAllMyEmployeesQuery) =>
          profileAdapter.addMany(
            profileAdapter.getInitialState(),
            response.myShop?.employees ?? []
          )
      }),
      GetAllMyShopAddressServices: build.query<
        EntityState<Partial<ShopAddressService>, EntityId>,
        void
      >({
        query: (variables) => ({
          document: GetAllMyShopAddressServicesDocument,
          variables
        }),
        transformResponse: (response: GetAllMyShopAddressServicesQuery) =>
          shopAddressServiceAdapter.addMany(
            shopAddressServiceAdapter.getInitialState(),
            response.myShop?.shopAddressServices ?? []
          )
      }),
      GetAllMyShopAddresses: build.query<
        EntityState<Partial<ShopAddress>, EntityId>,
        void
      >({
        query: (variables) => ({
          document: GetAllMyShopAddressesDocument,
          variables
        }),
        transformResponse: (response: GetAllMyShopAddressesQuery) =>
          shopAddressAdapter.addMany(
            shopAddressAdapter.getInitialState(),
            response.myShop?.shopAddresses ?? []
          )
      })
    }),
    overrideExisting: true
  })
  .enhanceEndpoints({
    addTagTypes: [
      ADDRESSES,
      BOOKINGS,
      CALENDAR_EVENTS,
      CUSTOMERS,
      EMPLOYEES,
      INVITES,
      ME,
      MY_PROFILE_DETAILS,
      NOTIFICATIONS,
      SERVICES,
      SHOP,
      QUOTE_TEMPLATES,
      QUOTE
    ],
    endpoints: {
      AddPaymentMethod: {
        invalidatesTags: () => [
          {
            type: MY_PROFILE_DETAILS
          }
        ]
      },
      CancelMyAppointmentById: {
        invalidatesTags: () => [
          {
            type: BOOKINGS
          }
        ]
      },
      CancelSubscription: {
        invalidatesTags: () => [
          {
            type: MY_PROFILE_DETAILS
          }
        ]
      },
      CreateMyCalendarEvent: {
        invalidatesTags: () => [
          {
            type: BOOKINGS
          }
        ]
      },
      CreateMyQuoteTemplate: {
        invalidatesTags: () => [
          {
            type: QUOTE_TEMPLATES
          }
        ]
      },
      CreateMyShopAddress: {
        invalidatesTags: () => [
          {
            type: ADDRESSES
          }
        ]
      },
      CreateMyShopAddressService: {
        invalidatesTags: () => [
          {
            type: SERVICES
          }
        ]
      },
      CreateSubscription: {
        invalidatesTags: () => [
          {
            type: MY_PROFILE_DETAILS
          }
        ]
      },
      DeleteMyOpenSlotById: {
        invalidatesTags: () => [
          {
            type: BOOKINGS
          }
        ]
      },
      DeleteMyQuoteTemplateById: {
        invalidatesTags: () => [
          {
            type: QUOTE_TEMPLATES
          }
        ]
      },
      DeleteMyShopAddressById: {
        invalidatesTags: () => [
          {
            type: ADDRESSES
          }
        ]
      },
      DeleteMyShopAddressServiceById: {
        invalidatesTags: () => [
          {
            type: SERVICES
          }
        ]
      },
      DenyQuoteById: {
        invalidatesTags: () => [
          {
            type: QUOTE
          }
        ]
      },
      GetAllMyCustomers: {
        providesTags: () => [
          {
            type: EMPLOYEES
          }
        ]
      },
      GetAllMyEmployees: {
        providesTags: () => [
          {
            type: EMPLOYEES
          }
        ]
      },
      GetAllMyShopAddressServices: {
        providesTags: () => [
          {
            type: SERVICES
          }
        ]
      },
      GetAllMyShopAddresses: {
        providesTags: () => [
          {
            type: ADDRESSES
          }
        ]
      },
      GetMyCalendarEventDateRange: {
        onCacheEntryAdded: async (
          _,
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) => {
          const { accessToken } = (await fetchAuthSession()).tokens ?? {};
          const authorization = {
            Authorization: accessToken?.toString() ?? '',
            host: process.env.GATSBY_GRAPHQL_WS_HOST!
          };
          const client = createSubscriptionClient({
            header: authorization,
            payload: {},
            subProtocol: process.env
              .GATSBY_GRAPHQL_WS_SUBPROTOCOL as GraphqlWSSubProtocol,
            url: process.env.GATSBY_GRAPHQL_WS_URL!
          });

          await client.connect();

          try {
            // wait for the initial query to resolve before proceeding
            await cacheDataLoaded;

            const state = getState() as any;
            const result = api.endpoints.GetMyProfile.select()(state);

            client.subscribe<
              OnMyCalendarEventUpdatedSubscription,
              OnMyCalendarEventUpdatedSubscriptionVariables
            >(
              OnMyCalendarEventUpdatedDocument,
              { shopId: result?.data?.me?.shopId! },
              { authorization },
              (data) => {
                updateCachedData((draft) => {
                  let isUpdated = false;
                  let isDeleted = false;

                  for (
                    let i = 0;
                    i < (draft?.myCalendarEventsByDateRange?.length ?? 0);
                    i++
                  ) {
                    if (
                      draft?.myCalendarEventsByDateRange?.[i] &&
                      data?.onMyCalendarEventUpdated?._id ===
                        draft?.myCalendarEventsByDateRange?.[i]?._id
                    ) {
                      if (
                        data?.onMyCalendarEventUpdated?.deleted ||
                        data?.onMyCalendarEventUpdated?.booking?.state ===
                          CalendarEventBookingState.Canceled
                      ) {
                        isDeleted = true;
                        const index =
                          draft?.myCalendarEventsByDateRange?.findIndex(
                            (calendarEvent) =>
                              calendarEvent._id ===
                              data?.onMyCalendarEventUpdated?._id
                          );

                        if (index) {
                          draft?.myCalendarEventsByDateRange?.splice(index, 1);
                        }
                        break;
                      } else {
                        draft.myCalendarEventsByDateRange[i] =
                          data?.onMyCalendarEventUpdated;
                        isUpdated = true;
                        // TODO: try to find a way to use translations here
                        notifications.show({
                          message: formatDate(
                            data?.onMyCalendarEventUpdated?.dateRange?.from
                          ),
                          title:
                            data?.onMyCalendarEventUpdated?.type ===
                            CalendarEventType.OpenSlot
                              ? 'OPEN SLOT UPDATED'
                              : 'APPOINTMENT UPDATED'
                        });
                      }
                    }
                  }

                  if (
                    !isUpdated &&
                    !isDeleted &&
                    data?.onMyCalendarEventUpdated &&
                    data?.onMyCalendarEventUpdated?.booking?.state !==
                      CalendarEventBookingState.Canceled
                  ) {
                    draft?.myCalendarEventsByDateRange?.push(
                      data.onMyCalendarEventUpdated
                    );
                    notifications.show({
                      message: formatDate(
                        data?.onMyCalendarEventUpdated?.dateRange?.from
                      ),
                      title:
                        data?.onMyCalendarEventUpdated?.type ===
                        CalendarEventType.OpenSlot
                          ? 'NEW OPEN SLOT CREATED'
                          : 'NEW APPOINTMENT CREATED'
                    });
                  }
                });
              }
            );
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          client.close();
        },
        providesTags: () => [
          {
            type: BOOKINGS
          }
        ]
      },
      GetMyEmployees: {
        providesTags: () => [
          {
            type: EMPLOYEES
          }
        ]
      },
      GetMyInvites: {
        providesTags: () => [
          {
            type: INVITES
          }
        ]
      },
      GetMyNotifications: {
        providesTags: () => [
          {
            type: NOTIFICATIONS
          }
        ]
      },
      GetMyProfile: {
        onCacheEntryAdded: async (
          _,
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) => {
          const { accessToken } = (await fetchAuthSession()).tokens ?? {};
          const authorization = {
            Authorization: accessToken?.toString() ?? '',
            host: process.env.GATSBY_GRAPHQL_WS_HOST!
          };
          const client = createSubscriptionClient({
            header: authorization,
            payload: {},
            subProtocol: process.env
              .GATSBY_GRAPHQL_WS_SUBPROTOCOL as GraphqlWSSubProtocol,
            url: process.env.GATSBY_GRAPHQL_WS_URL!
          });

          await client.connect();

          try {
            // wait for the initial query to resolve before proceeding
            const { data: cache } = await cacheDataLoaded;

            if (cache?.me?.shopId) {
              client.subscribe<
                OnProfileUpdatedByIdSubscription,
                OnProfileUpdatedByIdSubscriptionVariables
              >(
                OnProfileUpdatedByIdDocument,
                { _id: cache.me._id },
                { authorization },
                (data) => {
                  updateCachedData((draft) => {
                    if (data?.onProfileUpdatedById) {
                      Object.assign(
                        draft?.me ?? {},
                        data?.onProfileUpdatedById
                      );
                    }
                  });
                }
              );
            }
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          client.close();
        },
        providesTags: () => [
          {
            type: ME
          }
        ]
      },
      GetMyProfileDetail: {
        providesTags: () => [
          {
            type: MY_PROFILE_DETAILS
          }
        ]
      },
      GetMyQuoteTemplates: {
        providesTags: () => [
          {
            type: QUOTE_TEMPLATES
          }
        ]
      },
      GetMyQuotes: {
        onCacheEntryAdded: async (
          _,
          { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }
        ) => {
          const { accessToken } = (await fetchAuthSession()).tokens ?? {};

          const authorization = {
            Authorization: accessToken?.toString() ?? '',
            host: process.env.GATSBY_GRAPHQL_WS_HOST!
          };
          const client = createSubscriptionClient({
            header: authorization,
            payload: {},
            subProtocol: process.env
              .GATSBY_GRAPHQL_WS_SUBPROTOCOL as GraphqlWSSubProtocol,
            url: process.env.GATSBY_GRAPHQL_WS_URL!
          });

          await client.connect();

          try {
            // wait for the initial query to resolve before proceeding
            await cacheDataLoaded;

            const state = getState() as any;
            const result = api.endpoints.GetMyProfile.select()(state);

            client.subscribe<
              OnQuoteUpdatedSubscription,
              OnQuoteUpdatedSubscriptionVariables
            >(
              OnQuoteUpdatedDocument,
              { shopId: result?.data?.me?.shopId! },
              { authorization },
              (data) => {
                updateCachedData((draft) => {
                  if (data?.onQuoteUpdated) {
                    draft?.myQuotes?.items?.unshift(data.onQuoteUpdated);
                    notifications.show({
                      message: data?.onQuoteUpdated?.customer?.name,
                      title: 'NEW QUOTE REQUEST'
                    });
                  }
                });
              }
            );
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          client.close();
        },
        providesTags: () => [
          {
            type: QUOTE
          }
        ]
      },
      GetMyShop: {
        providesTags: () => [
          {
            type: SHOP
          }
        ]
      },
      GetMyShopAddressServices: {
        providesTags: () => [
          {
            type: SERVICES
          }
        ]
      },
      GetMyShopAddresses: {
        providesTags: () => [
          {
            type: ADDRESSES
          }
        ]
      },
      InviteEmployee: {
        invalidatesTags: () => [
          {
            type: INVITES
          }
        ]
      },
      UpdateEmployeeRoleById: {
        invalidatesTags: () => [
          {
            type: EMPLOYEES
          }
        ]
      },
      UpdateMyCalendarEvent: {
        invalidatesTags: () => [
          {
            type: BOOKINGS
          }
        ]
      },
      UpdateMyMedia: {
        invalidatesTags: () => [{ type: SHOP }]
      },
      UpdateMyNotificationsRead: {
        invalidatesTags: () => [{ type: NOTIFICATIONS }]
      },
      UpdateMyProfile: {
        invalidatesTags: () => [
          {
            type: ME
          }
        ]
      },
      UpdateMyQuoteById: {
        invalidatesTags: () => [
          {
            type: QUOTE
          }
        ]
      },
      UpdateMyQuoteTemplate: {
        invalidatesTags: () => [
          {
            type: QUOTE_TEMPLATES
          }
        ]
      },
      UpdateMyShopAddressById: {
        invalidatesTags: () => [
          {
            type: ADDRESSES
          }
        ]
      },
      UpdateMyShopAddressServiceById: {
        invalidatesTags: () => [
          {
            type: SERVICES
          }
        ]
      },
      UpdateSubscription: {
        invalidatesTags: () => [
          {
            type: MY_PROFILE_DETAILS
          }
        ]
      }
    }
  });

export const {
  useAddPaymentMethodMutation,
  useCancelMyAppointmentByIdMutation,
  useCancelSubscriptionMutation,
  useCreateMyCalendarEventMutation,
  useCreateMyQuoteTemplateMutation,
  useCreateMyShopAddressMutation,
  useCreateMyShopAddressServiceMutation,
  useCreateSubscriptionMutation,
  useDeleteMyOpenSlotByIdMutation,
  useDeleteMyQuoteTemplateByIdMutation,
  useDeleteMyShopAddressByIdMutation,
  useDeleteMyShopAddressServiceByIdMutation,
  useDenyQuoteByIdMutation,
  useGetAllMyCustomersQuery,
  useGetAllMyEmployeesQuery,
  useGetAllMyShopAddressServicesQuery,
  useGetAllMyShopAddressesQuery,
  useGetMyCalendarEventDateRangeQuery,
  useGetMyEmployeesQuery,
  useGetMyInvitesQuery,
  useGetMyInvoicesQuery,
  useGetMyProfileDetailQuery,
  useGetMyProfileQuery,
  useGetMyQuoteTemplatesQuery,
  useGetMyQuotesQuery,
  useGetMyShopAddressServicesQuery,
  useGetMyShopAddressesQuery,
  useGetMyShopQuery,
  useGetSubscriptionProductsQuery,
  useInviteEmployeeMutation,
  useUpdateEmployeeRoleByIdMutation,
  useUpdateMyCalendarEventMutation,
  useUpdateMyDevicesTokenMutation,
  useUpdateMyMediaMutation,
  useUpdateMyProfileMutation,
  useUpdateMyQuoteByIdMutation,
  useUpdateMyQuoteTemplateMutation,
  useUpdateMyShopAddressByIdMutation,
  useUpdateMyShopAddressServiceByIdMutation,
  useUpdateMyShopNameAndBioMutation,
  useUpdateSubscriptionMutation
} = api;

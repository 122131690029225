import { AuthProvider } from '@/lib/contexts/auth-context';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import { Amplify } from 'aws-amplify';
import { type GatsbyBrowser } from 'gatsby';
import { Provider } from 'react-redux';

import './src/dayjs';
import { store } from './src/lib/store';
import { theme } from './src/theme';
import './wdyr';

Amplify.configure({
  API: {
    GraphQL: {
      apiKey: process.env.GATSBY_GRAPHQL_API_KEY || '',
      defaultAuthMode: 'userPool',
      endpoint: process.env.GATSBY_GRAPHQL_API_URL || ''
    }
  },
  Auth: {
    Cognito: {
      identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID!,
      userPoolClientId: process.env.GATSBY_USER_POOL_CLIENT_ID!,
      userPoolId: process.env.GATSBY_USER_POOL_ID!
    }
  },
  Storage: {
    S3: {
      bucket: process.env.GATSBY_PUBLIC_UPLOAD_BUCKET,
      region: process.env.GATSBY_AWS_REGION
    }
  }
});

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => {
  return (
    <Provider store={store}>
      <AuthProvider>{element}</AuthProvider>
    </Provider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications position={'top-center'} />
      {element}
    </MantineProvider>
  );
};

export const ADDRESSES = 'ADDRESSES';
export const CALENDAR_EVENTS = 'CALENDAR_EVENTS';
export const CUSTOMERS = 'CUSTOMERS';
export const EMPLOYEES = 'EMPLOYEES';
export const INVITES = 'INVITES';
export const ME = 'ME';
export const MY_PROFILE_DETAILS = 'MY_PROFILE_DETAILS';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const SERVICES = 'SERVICES';
export const SHOP = 'SHOP';
export const BOOKINGS = 'BOOKINGS';
export const QUOTE_TEMPLATES = 'QUOTE_TEMPLATES';
export const QUOTE = 'QUOTE';

import { EntityId, IdSelector, createEntityAdapter } from '@reduxjs/toolkit';

import {
  CalendarEvent,
  Customer,
  Profile,
  ShopAddress,
  ShopAddressService
} from '../graphql-api/generated';

const idSelector =
  <T extends { _id: string }>(
    path: keyof T = '_id'
  ): IdSelector<Partial<T>, EntityId> =>
  (model: Partial<T>) =>
    model[path] as EntityId;

export const calendarEventAdapter = createEntityAdapter({
  selectId: idSelector<CalendarEvent>()
});

export const customerAdapter = createEntityAdapter({
  selectId: idSelector<Customer>('name')
});

export const profileAdapter = createEntityAdapter({
  selectId: idSelector<Profile>()
});

export const shopAddressAdapter = createEntityAdapter({
  selectId: idSelector<ShopAddress>()
});

export const shopAddressServiceAdapter = createEntityAdapter({
  selectId: idSelector<ShopAddressService>()
});

import { useCallback, useMemo } from 'react';

import { useGetMyProfileQuery } from '../graphql-api/api';

interface UsePermissionRet {
  canReadCalendarEvent: boolean;
  canReadInvite: boolean;
  canReadInvoice: boolean;
  canReadQuote: boolean;
  canReadQuoteTemplate: boolean;
  canReadShopAddressService: boolean;
  canReadShopEmployees: boolean;
  canWriteCalendarEvent: boolean;
  canWriteInvite: boolean;
  canWriteQuote: boolean;
  canWriteQuoteTemplate: boolean;
  canWriteShop: boolean;
  canWriteShopAddress: boolean;
  canWriteShopAddressService: boolean;
  canWriteShopEmployees: boolean;
  canWriteShopSubscriptionPlan: boolean;

  isAllowed: boolean;
  isAllowedEvery: (permissions: string[]) => boolean;
  isAllowedSome: (permissions: string[]) => boolean;
  isLoading: boolean;
}

export const usePermissions = (
  requiredPermissions?: string[]
): UsePermissionRet => {
  const { data, isLoading } = useGetMyProfileQuery();
  const isAllowedEvery = useCallback(
    (value: string[]) =>
      value.every((p) => !!data?.me?.permissions?.includes(p)),
    [data?.me?.permissions]
  );
  const isAllowedSome = useCallback(
    (value: string[]) =>
      value.some((p) => !!data?.me?.permissions?.includes(p)),
    [data?.me?.permissions]
  );

  const ret = useMemo(
    () => ({
      canReadCalendarEvent: isAllowedEvery(['calendarEvent.read']),
      canReadInvite: isAllowedEvery(['invite.read']),
      canReadInvoice: isAllowedEvery(['shop.invoice.read']),
      canReadQuote: isAllowedEvery(['quote.read']),
      canReadQuoteTemplate: isAllowedEvery(['quoteTemplate.read']),
      canReadShopAddressService: isAllowedEvery(['shopAddressService.read']),
      canReadShopEmployees: isAllowedEvery(['shop.employees.read']),
      canWriteCalendarEvent: isAllowedEvery(['calendarEvent.write']),
      canWriteInvite: isAllowedEvery(['invite.write']),
      canWriteQuote: isAllowedEvery(['quote.write']),
      canWriteQuoteTemplate: isAllowedEvery(['quoteTemplate.write']),
      canWriteShop: isAllowedEvery(['shop.write']),
      canWriteShopAddress: isAllowedEvery(['shopAddress.write']),
      canWriteShopAddressService: isAllowedEvery(['shopAddressService.write']),
      canWriteShopEmployees: isAllowedEvery(['shop.employees.write']),
      canWriteShopSubscriptionPlan: isAllowedEvery([
        'shop.subscriptionPlan.read'
      ]),

      isAllowed:
        !requiredPermissions?.length || isAllowedEvery(requiredPermissions),
      isAllowedEvery,
      isAllowedSome,
      isLoading
    }),
    [isAllowedEvery, isAllowedSome, requiredPermissions, isLoading]
  );

  return ret;
};

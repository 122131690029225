import { configureStore } from '@reduxjs/toolkit';

import { api } from '../graphql-api/base-api';

// eslint-disable-next-line
export const makeStore = () => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    reducer: {
      [api.reducerPath]: api.reducer
    }
  });
};

export const store = makeStore();

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

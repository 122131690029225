import { api } from './base-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDateTime: { input: string; output: string };
  AWSEmail: { input: string; output: string };
  AWSIPAddress: { input: any; output: any };
  AWSJSON: { input: string; output: string };
  AWSPhone: { input: any; output: any };
  AWSTime: { input: any; output: any };
  AWSTimestamp: { input: string; output: string };
  AWSURL: { input: string; output: string };
};

export type AcceptInviteInput = {
  token: Scalars['String']['input'];
};

export type BaseEntity = {
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type CalendarEvent = BaseEntity & {
  __typename?: 'CalendarEvent';
  _id: Scalars['ID']['output'];
  booking?: Maybe<CalendarEventBooking>;
  calendarEventIntentId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  dateRange: DateRange;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  employee?: Maybe<Profile>;
  shopAddress?: Maybe<ShopAddress>;
  shopId: Scalars['ID']['output'];
  type: CalendarEventType;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type CalendarEventAvailability = {
  __typename?: 'CalendarEventAvailability';
  date: Scalars['String']['output'];
  employees?: Maybe<Array<CalendarEventAvailabilityEmployees>>;
  shopAddressId: Scalars['ID']['output'];
  shopId: Scalars['ID']['output'];
};

export type CalendarEventAvailabilityEmployees = {
  __typename?: 'CalendarEventAvailabilityEmployees';
  _id: Scalars['ID']['output'];
  calendarEventId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
};

export type CalendarEventBooking = {
  __typename?: 'CalendarEventBooking';
  canceledByUser?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<CurrencyCode>;
  customer: Customer;
  deposit?: Maybe<Scalars['Float']['output']>;
  paymentInfo?: Maybe<PaymentInfo>;
  price: Scalars['Float']['output'];
  shopAddressService: ShopAddressService;
  state: CalendarEventBookingState;
  withPayment: Scalars['Boolean']['output'];
};

export enum CalendarEventBookingState {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export enum CalendarEventType {
  Appointment = 'APPOINTMENT',
  OpenSlot = 'OPEN_SLOT'
}

export type Card = {
  __typename?: 'Card';
  brand?: Maybe<Scalars['String']['output']>;
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CardInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  expirationMonth: Scalars['Int']['input'];
  expirationYear: Scalars['Int']['input'];
  last4: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCustomerInput = {
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateMyShopAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  fullAddress: Scalars['String']['input'];
  location: GeoPointInput;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<OpeningHoursInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMyShopAddressServiceInput = {
  availableSeats?: InputMaybe<Scalars['Int']['input']>;
  category: ShopAddressServiceCategory;
  currency: CurrencyCode;
  deposit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration: HourMinuteInput;
  employeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quoteTemplateId?: InputMaybe<Scalars['ID']['input']>;
  shopAddressIds: Array<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrUpdateCalendarEventInput = {
  customer?: InputMaybe<CreateCustomerInput>;
  dateRange: DateRangeInput;
  deposit?: InputMaybe<Scalars['Float']['input']>;
  employeeId: Scalars['ID']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  shopAddressId: Scalars['ID']['input'];
  shopAddressServiceId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<CalendarEventType>;
  withPayment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSubscriptionInput = {
  paymentMethodId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
};

export enum CurrencyCode {
  Eur = 'EUR',
  Gpb = 'GPB',
  Usd = 'USD'
}

export type Customer = BaseEntity & {
  __typename?: 'Customer';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  paymentCustormerId?: Maybe<Scalars['ID']['output']>;
  phone: Scalars['String']['output'];
  shopId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type CustomerListResult = ListResult & {
  __typename?: 'CustomerListResult';
  items: Array<Customer>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type DateRange = {
  __typename?: 'DateRange';
  from: Scalars['AWSDateTime']['output'];
  to: Scalars['AWSDateTime']['output'];
};

export type DateRangeInput = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type DayOpeningHour = {
  __typename?: 'DayOpeningHour';
  end?: Maybe<HourMinute>;
  id: Scalars['Int']['output'];
  start?: Maybe<HourMinute>;
  type: OpeningDayType;
};

export type DayOpeningHourInput = {
  end?: InputMaybe<HourMinuteInput>;
  id: Scalars['Int']['input'];
  start?: InputMaybe<HourMinuteInput>;
  type: OpeningDayType;
};

export type DeletedCalendarEvent = {
  __typename?: 'DeletedCalendarEvent';
  _id: Scalars['ID']['output'];
  shopId: Scalars['ID']['output'];
};

export type DeviceEndpoint = {
  __typename?: 'DeviceEndpoint';
  deviceInformation?: Maybe<DeviceInformation>;
  deviceToken: Scalars['String']['output'];
  endpointArn: Scalars['String']['output'];
};

export type DeviceInformation = {
  __typename?: 'DeviceInformation';
  androidSdkVersion?: Maybe<Scalars['Int']['output']>;
  iosVersion?: Maybe<Scalars['Int']['output']>;
  model: Scalars['String']['output'];
  osVersion?: Maybe<Scalars['String']['output']>;
  platform: DevicePlatformType;
};

export enum DevicePlatformType {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export type FilterCustomerInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type FilterInviteCriteria = {
  acceptedBy?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<ProfileRole>;
  state?: InputMaybe<InviteState>;
};

export type FilterInvoiceCriteria = {
  status?: InputMaybe<InvoiceStatus>;
};

export type FilterProfileCriteria = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  sub?: InputMaybe<Scalars['String']['input']>;
};

export type FilterQuoteTemplatesCriteria = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterQuotesCriteria = {
  customer?: InputMaybe<FilterCustomerInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<QuoteState>;
};

export type FilterShopAddressServiceCriteria = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<ShopAddressServiceCategory>;
  currency?: InputMaybe<CurrencyCode>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  shopAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GeoPointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type GroupedCalendarEvent = {
  __typename?: 'GroupedCalendarEvent';
  events?: Maybe<Array<CalendarEvent>>;
  firstDate: Scalars['AWSDateTime']['output'];
};

export type HourMinute = {
  __typename?: 'HourMinute';
  hour: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type HourMinuteInput = {
  hour?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export enum Interval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type Invite = BaseEntity & {
  __typename?: 'Invite';
  _id: Scalars['ID']['output'];
  acceptedBy?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  expiresAt: Scalars['AWSDateTime']['output'];
  role: ProfileRole;
  shopId: Scalars['String']['output'];
  state: InviteState;
  token: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type InviteListResult = ListResult & {
  __typename?: 'InviteListResult';
  items: Array<Invite>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type InviteMyEmployeeInput = {
  email: Scalars['String']['input'];
  role: ProfileRole;
};

export type InviteSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  acceptedBy?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['Int']['input']>;
  expiresAt?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['Int']['input']>;
  shopId?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Int']['input']>;
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export enum InviteState {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type Invoice = BaseEntity & {
  __typename?: 'Invoice';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  customerId: Scalars['String']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  invoiceId: Scalars['String']['output'];
  pdfUrl: Scalars['String']['output'];
  shopId: Scalars['ID']['output'];
  status: InvoiceStatus;
  subscriptionPlan: SubscriptionPlan;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type InvoiceListResult = ListResult & {
  __typename?: 'InvoiceListResult';
  items: Array<Invoice>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type InvoiceSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Int']['input']>;
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export enum InvoiceStatus {
  Open = 'OPEN',
  Paid = 'PAID',
  PaymentFailed = 'PAYMENT_FAILED'
}

export type ListResult = {
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum MediaType {
  Banner = 'BANNER',
  ProfilePicture = 'PROFILE_PICTURE'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a Payment method to the shop */
  addPaymentMethod?: Maybe<Shop>;
  /** Cancel an appointment */
  cancelMyAppointmentById?: Maybe<Scalars['Boolean']['output']>;
  /** Cancel the shop's subscription */
  cancelSubscription?: Maybe<Scalars['Boolean']['output']>;
  /** Creates a Calendar event */
  createMyCalendarEvent?: Maybe<CalendarEvent>;
  /** Creates my quote template */
  createMyQuoteTemplate: QuoteTemplate;
  /** Creates my shop address */
  createMyShopAddress: ShopAddress;
  /** Creates my shop address service */
  createMyShopAddressService: ShopAddressService;
  /** Creates a subscription */
  createSubscription?: Maybe<Scalars['String']['output']>;
  /** Deletes my open slot */
  deleteMyOpenSlotById: Scalars['Boolean']['output'];
  /** Deletes my quote template */
  deleteMyQuoteTemplateById: Scalars['Int']['output'];
  /** Deletes my shop address */
  deleteMyShopAddressById: Scalars['Int']['output'];
  /** Deletes my shop address service */
  deleteMyShopAddressServiceById: Scalars['Int']['output'];
  /** Deny quote by id */
  denyQuoteById?: Maybe<Quote>;
  /** Invite an employee to its shop */
  inviteMyEmployee: Scalars['ID']['output'];
  /** Notifies a calendar event by id */
  notifyCalendarEventUpdatedById?: Maybe<CalendarEvent>;
  /** Notifies a profile by id */
  notifyProfileUpdatedById?: Maybe<Profile>;
  /** Notifies a quote by id */
  notifyQuoteUpdatedById?: Maybe<Quote>;
  /** Updates a Calendar event */
  updateMyCalendarEvent?: Maybe<CalendarEvent>;
  /** Update my device tokens */
  updateMyDevicesToken?: Maybe<Profile>;
  /** Update my employee role by id */
  updateMyEmployeeRoleById?: Maybe<Profile>;
  /** Update my media */
  updateMyMedia: Shop;
  /** Get my notifications */
  updateMyNotificationsRead: Scalars['Boolean']['output'];
  /** Update my profile */
  updateMyProfile?: Maybe<Profile>;
  /** Updates my quote by id */
  updateMyQuoteById: Quote;
  /** Updates my quote template */
  updateMyQuoteTemplate: QuoteTemplate;
  /** Update my shop */
  updateMyShop?: Maybe<Shop>;
  /** Update my shop address by id */
  updateMyShopAddressById?: Maybe<ShopAddress>;
  /** Updates my shop address service */
  updateMyShopAddressServiceById?: Maybe<ShopAddressService>;
  /** Update my shop */
  updateMyShopNameAndBio?: Maybe<Shop>;
  /** Update a shop's subscription */
  updateSubscription?: Maybe<Scalars['String']['output']>;
};

export type MutationAddPaymentMethodArgs = {
  input: ShopAddPaymentMethodInput;
};

export type MutationCancelMyAppointmentByIdArgs = {
  _id: Scalars['ID']['input'];
  cancelReason: Scalars['String']['input'];
};

export type MutationCreateMyCalendarEventArgs = {
  input: CreateOrUpdateCalendarEventInput;
};

export type MutationCreateMyQuoteTemplateArgs = {
  input: WriteMyQuoteTemplateInput;
};

export type MutationCreateMyShopAddressArgs = {
  input: CreateMyShopAddressInput;
};

export type MutationCreateMyShopAddressServiceArgs = {
  input: CreateMyShopAddressServiceInput;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationDeleteMyOpenSlotByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDeleteMyQuoteTemplateByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDeleteMyShopAddressByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDeleteMyShopAddressServiceByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDenyQuoteByIdArgs = {
  _id: Scalars['ID']['input'];
  deniedReason: Scalars['String']['input'];
};

export type MutationInviteMyEmployeeArgs = {
  input: InviteMyEmployeeInput;
};

export type MutationNotifyCalendarEventUpdatedByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationNotifyProfileUpdatedByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationNotifyQuoteUpdatedByIdArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateMyCalendarEventArgs = {
  _id: Scalars['ID']['input'];
  input: CreateOrUpdateCalendarEventInput;
};

export type MutationUpdateMyDevicesTokenArgs = {
  input: UpdateMyDevicesTokenInput;
};

export type MutationUpdateMyEmployeeRoleByIdArgs = {
  _id: Scalars['ID']['input'];
  input: UpdateMyEmployeeRoleByIdInput;
};

export type MutationUpdateMyMediaArgs = {
  input: UpdateMyMediaInput;
};

export type MutationUpdateMyNotificationsReadArgs = {
  _ids: Array<Scalars['ID']['input']>;
};

export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};

export type MutationUpdateMyQuoteByIdArgs = {
  _id: Scalars['ID']['input'];
  input: UpdateMyQuoteByIdInput;
};

export type MutationUpdateMyQuoteTemplateArgs = {
  _id: Scalars['ID']['input'];
  input: WriteMyQuoteTemplateInput;
};

export type MutationUpdateMyShopArgs = {
  input: UpdateMyShopInput;
};

export type MutationUpdateMyShopAddressByIdArgs = {
  _id: Scalars['ID']['input'];
  input: UpdateMyShopAddressByIdInput;
};

export type MutationUpdateMyShopAddressServiceByIdArgs = {
  _id: Scalars['ID']['input'];
  input: UpdateMyShopAddressServiceByIdInput;
};

export type MutationUpdateMyShopNameAndBioArgs = {
  input: UpdateMyShopNameAndBioInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type Notification = BaseEntity & {
  __typename?: 'Notification';
  _id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<NotificationPriority>;
  readAt: Scalars['AWSDateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export enum NotificationPriority {
  High = 'HIGH',
  Normal = 'NORMAL'
}

export enum OpeningDayType {
  Closed = 'CLOSED',
  Na = 'NA',
  Opened = 'OPENED'
}

export type OpeningHours = {
  __typename?: 'OpeningHours';
  friday?: Maybe<DayOpeningHour>;
  monday?: Maybe<DayOpeningHour>;
  saturday?: Maybe<DayOpeningHour>;
  sunday?: Maybe<DayOpeningHour>;
  thursday?: Maybe<DayOpeningHour>;
  tuesday?: Maybe<DayOpeningHour>;
  wednesday?: Maybe<DayOpeningHour>;
};

export type OpeningHoursInput = {
  friday?: InputMaybe<DayOpeningHourInput>;
  monday?: InputMaybe<DayOpeningHourInput>;
  saturday?: InputMaybe<DayOpeningHourInput>;
  sunday?: InputMaybe<DayOpeningHourInput>;
  thursday?: InputMaybe<DayOpeningHourInput>;
  tuesday?: InputMaybe<DayOpeningHourInput>;
  wednesday?: InputMaybe<DayOpeningHourInput>;
};

export type Pagination = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export enum PaymentChoice {
  Deposit = 'DEPOSIT',
  Free = 'FREE',
  Full = 'FULL'
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  checkoutSessionId?: Maybe<Scalars['String']['output']>;
  latestChargeId?: Maybe<Scalars['String']['output']>;
  paymentChoice: PaymentChoice;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  refundAmount?: Maybe<Scalars['Float']['output']>;
  refundAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  card: Card;
  id: Scalars['String']['output'];
};

export type PaymentMethodInput = {
  card: CardInput;
  id: Scalars['String']['input'];
};

export type Picture = {
  __typename?: 'Picture';
  large?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  small?: Maybe<Scalars['String']['output']>;
};

export type PictureInput = {
  large?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  small?: InputMaybe<Scalars['String']['input']>;
};

export type Price = {
  __typename?: 'Price';
  active?: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recurringInterval?: Maybe<Interval>;
  unitAmount: Scalars['Int']['output'];
};

export type Product = {
  __typename?: 'Product';
  active?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<SubscriptionPlanType>;
  price?: Maybe<Price>;
};

export type Profile = BaseEntity & {
  __typename?: 'Profile';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  deviceEndpoints?: Maybe<Array<DeviceEndpoint>>;
  email: Scalars['String']['output'];
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  role: ProfileRole;
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['ID']['output']>;
  sub: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ProfileListResult = ListResult & {
  __typename?: 'ProfileListResult';
  items: Array<Profile>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum ProfileRole {
  Locksy = 'LOCKSY',
  ShopEmployee = 'SHOP_EMPLOYEE',
  ShopOwner = 'SHOP_OWNER',
  SuperAdmin = 'SUPER_ADMIN'
}

export type ProfileSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['Int']['input']>;
  sub?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Int']['input']>;
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Gets my profile */
  me?: Maybe<Profile>;
  /** Get a list of calendar events for the user's shops */
  myCalendarEventsByDateRange?: Maybe<Array<CalendarEvent>>;
  /** Get a list of calendar events grouped by overlapping for the user's shops */
  myCalendarEventsByDateRangeGrouped?: Maybe<Array<GroupedCalendarEvent>>;
  /** Get a list of calendar events grouped by startDate */
  myCalendarEventsByDateRangeGroupedByDays?: Maybe<Array<GroupedCalendarEvent>>;
  /** Get a list of my employees invites */
  myEmployeeInvites?: Maybe<InviteListResult>;
  /** Get a list of my employees */
  myEmployees?: Maybe<ProfileListResult>;
  /** Get a list of my invoices */
  myInvoices?: Maybe<InvoiceListResult>;
  /** Get my notifications */
  myNotifications?: Maybe<Array<Notification>>;
  /** Get a list of my quote templates */
  myQuoteTemplates?: Maybe<QuoteTemplateResult>;
  /** Get a list of my quotes */
  myQuotes?: Maybe<QuoteResult>;
  /** Gets my shop */
  myShop?: Maybe<Shop>;
  /** Gets my shop's address services */
  myShopAddressServices?: Maybe<ShopAddressServiceListResult>;
  /** Gets my shop's address services */
  myShopAddresses?: Maybe<ShopAddressListResult>;
  /** Gets all subscription products */
  subscriptionProducts: Array<Product>;
};

export type QueryMyCalendarEventsByDateRangeArgs = {
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  from: Scalars['String']['input'];
  shopAddressIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  to: Scalars['String']['input'];
  types?: InputMaybe<Array<CalendarEventType>>;
};

export type QueryMyCalendarEventsByDateRangeGroupedArgs = {
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  from: Scalars['String']['input'];
  shopAddressIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  to: Scalars['String']['input'];
  types?: InputMaybe<Array<CalendarEventType>>;
};

export type QueryMyCalendarEventsByDateRangeGroupedByDaysArgs = {
  employeeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  from: Scalars['String']['input'];
  shopAddressIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  to: Scalars['String']['input'];
  types?: InputMaybe<Array<CalendarEventType>>;
};

export type QueryMyEmployeeInvitesArgs = {
  filter?: InputMaybe<FilterInviteCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<InviteSort>;
};

export type QueryMyEmployeesArgs = {
  filter?: InputMaybe<FilterProfileCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<ProfileSort>;
};

export type QueryMyInvoicesArgs = {
  filter?: InputMaybe<FilterInvoiceCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<InvoiceSort>;
};

export type QueryMyQuoteTemplatesArgs = {
  filter?: InputMaybe<FilterQuoteTemplatesCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<QuoteTemplateSort>;
};

export type QueryMyQuotesArgs = {
  filter?: InputMaybe<FilterQuotesCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<QuoteSort>;
};

export type QueryMyShopAddressServicesArgs = {
  filter?: InputMaybe<FilterShopAddressServiceCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<ShopAddressServiceSort>;
};

export type QueryMyShopAddressesArgs = {
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<ShopAddressSort>;
};

export type Quote = BaseEntity & {
  __typename?: 'Quote';
  _id: Scalars['ID']['output'];
  answers: Array<QuoteAnswer>;
  comments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  customer: Customer;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  deniedReason?: Maybe<Scalars['String']['output']>;
  deposit?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<HourMinute>;
  price?: Maybe<Scalars['Int']['output']>;
  reference: Scalars['String']['output'];
  shopAddress: ShopAddress;
  shopAddressService: ShopAddressService;
  shopId: Scalars['ID']['output'];
  state: QuoteState;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type QuoteAnswer = {
  __typename?: 'QuoteAnswer';
  answerArrayString?: Maybe<Array<Scalars['String']['output']>>;
  answerBoolean?: Maybe<Scalars['Boolean']['output']>;
  answerString?: Maybe<Scalars['String']['output']>;
  choices?: Maybe<Array<QuoteTemplateChoice>>;
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: QuoteTemplateElementType;
};

export type QuoteResult = ListResult & {
  __typename?: 'QuoteResult';
  items: Array<Quote>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QuoteSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Int']['input']>;
};

export enum QuoteState {
  Denied = 'DENIED',
  Payed = 'PAYED',
  Pending = 'PENDING',
  Sent = 'SENT',
  Viewed = 'VIEWED'
}

export type QuoteTemplate = BaseEntity & {
  __typename?: 'QuoteTemplate';
  _id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  elements: Array<QuoteTemplateElement>;
  name: Scalars['String']['output'];
  shopId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type QuoteTemplateChoice = {
  __typename?: 'QuoteTemplateChoice';
  value: Scalars['String']['output'];
};

export type QuoteTemplateElement = {
  __typename?: 'QuoteTemplateElement';
  choices?: Maybe<Array<QuoteTemplateChoice>>;
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: QuoteTemplateElementType;
};

export enum QuoteTemplateElementType {
  Boolean = 'BOOLEAN',
  LongText = 'LONG_TEXT',
  MultiSelect = 'MULTI_SELECT',
  Photo = 'PHOTO',
  Select = 'SELECT',
  Text = 'TEXT'
}

export type QuoteTemplateResult = ListResult & {
  __typename?: 'QuoteTemplateResult';
  items: Array<QuoteTemplate>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type QuoteTemplateSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
};

export type Shop = BaseEntity & {
  __typename?: 'Shop';
  _id: Scalars['ID']['output'];
  allPaymentMethods?: Maybe<Array<PaymentMethod>>;
  banner?: Maybe<Picture>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  customers?: Maybe<Array<Customer>>;
  defaultPaymentMethod?: Maybe<PaymentMethod>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  employees?: Maybe<Array<Profile>>;
  media?: Maybe<Array<Picture>>;
  name: Scalars['String']['output'];
  onBoardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  paymentAccountId?: Maybe<Scalars['ID']['output']>;
  phoneNumber: Scalars['String']['output'];
  profilePicture?: Maybe<Picture>;
  shopAddressServices?: Maybe<Array<ShopAddressService>>;
  shopAddresses?: Maybe<Array<ShopAddress>>;
  socialLinks?: Maybe<Array<SocialLink>>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type ShopAddPaymentMethodInput = {
  asDefault?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod: PaymentMethodInput;
};

export type ShopAddress = BaseEntity & {
  __typename?: 'ShopAddress';
  _id: Scalars['ID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  fullAddress: Scalars['String']['output'];
  location?: Maybe<GeoPoint>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<OpeningHours>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  shop?: Maybe<Shop>;
  shopAddressServices?: Maybe<Array<ShopAddressService>>;
  shopId: Scalars['ID']['output'];
  streetNumber?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type ShopAddressListResult = ListResult & {
  __typename?: 'ShopAddressListResult';
  items: Array<ShopAddress>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ShopAddressService = BaseEntity & {
  __typename?: 'ShopAddressService';
  _id: Scalars['ID']['output'];
  availableSeats: Scalars['Int']['output'];
  category: ShopAddressServiceCategory;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  currency: CurrencyCode;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  deposit?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration: HourMinute;
  employeeIds?: Maybe<Array<Scalars['ID']['output']>>;
  employees?: Maybe<Array<Profile>>;
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quoteTemplate?: Maybe<QuoteTemplate>;
  quoteTemplateId?: Maybe<Scalars['ID']['output']>;
  shopAddressIds?: Maybe<Array<Scalars['ID']['output']>>;
  shopAddresses?: Maybe<Array<ShopAddress>>;
  shopId: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export enum ShopAddressServiceCategory {
  Care = 'CARE',
  Start = 'START'
}

export type ShopAddressServiceListResult = ListResult & {
  __typename?: 'ShopAddressServiceListResult';
  items: Array<ShopAddressService>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ShopAddressServiceSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  shopAddressId?: InputMaybe<Scalars['Int']['input']>;
};

export type ShopAddressSort = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Int']['input']>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  fullAddress?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['Int']['input']>;
  postalCode?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['Int']['input']>;
  shopId?: InputMaybe<Scalars['Int']['input']>;
  streetNumber?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Int']['input']>;
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type ShopInstagramPost = {
  __typename?: 'ShopInstagramPost';
  _id: Scalars['ID']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  mediaType?: Maybe<Scalars['String']['output']>;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ShopListResult = ListResult & {
  __typename?: 'ShopListResult';
  items: Array<Shop>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SocialLink = {
  __typename?: 'SocialLink';
  type: SocialLinkType;
  url: Scalars['String']['output'];
};

export enum SocialLinkType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Twitter = 'TWITTER'
}

export type Subscription = {
  __typename?: 'Subscription';
  onMyCalendarEventUpdated?: Maybe<CalendarEvent>;
  onProfileUpdatedById?: Maybe<Profile>;
  onQuoteUpdated?: Maybe<Quote>;
};

export type SubscriptionOnMyCalendarEventUpdatedArgs = {
  shopId: Scalars['ID']['input'];
};

export type SubscriptionOnProfileUpdatedByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type SubscriptionOnQuoteUpdatedArgs = {
  shopId: Scalars['ID']['input'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  name?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  product?: Maybe<Product>;
  status?: Maybe<SubscriptionPlanStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionPlanStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export enum SubscriptionPlanType {
  Essential = 'ESSENTIAL',
  Free = 'FREE'
}

export type UpdateDeviceInformationInput = {
  androidSdkVersion?: InputMaybe<Scalars['Int']['input']>;
  iosVersion?: InputMaybe<Scalars['Int']['input']>;
  model: Scalars['String']['input'];
  osVersion?: InputMaybe<Scalars['String']['input']>;
  platform: DevicePlatformType;
};

export type UpdateMyDevicesTokenInput = {
  deviceInformation: UpdateDeviceInformationInput;
  deviceToken: Scalars['String']['input'];
};

export type UpdateMyEmployeeRoleByIdInput = {
  role: ProfileRole;
};

export type UpdateMyMediaInput = {
  fileName: Scalars['String']['input'];
  type: MediaType;
};

export type UpdateMyProfileInput = {
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMyQuoteByIdInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  deposit?: InputMaybe<Scalars['Int']['input']>;
  duration: HourMinuteInput;
  price: Scalars['Int']['input'];
};

export type UpdateMyShopAddressByIdInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<GeoPointInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<OpeningHoursInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMyShopAddressServiceByIdInput = {
  availableSeats?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<ShopAddressServiceCategory>;
  currency?: InputMaybe<CurrencyCode>;
  deposit?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<HourMinuteInput>;
  employeeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  quoteTemplateId?: InputMaybe<Scalars['ID']['input']>;
  shopAddressIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMyShopInput = {
  banner?: InputMaybe<PictureInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<PictureInput>;
};

export type UpdateMyShopNameAndBioInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriptionInput = {
  paymentMethodId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type WriteMyQuoteTemplateInput = {
  elements: Array<WriteMyQuoteTemplateInputElementInput>;
  name: Scalars['String']['input'];
};

export type WriteMyQuoteTemplateInputElementChoiceInput = {
  value: Scalars['String']['input'];
};

export type WriteMyQuoteTemplateInputElementInput = {
  choices?: InputMaybe<Array<WriteMyQuoteTemplateInputElementChoiceInput>>;
  label: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  type: QuoteTemplateElementType;
};

type ListResultFragment_CustomerListResult_Fragment = {
  __typename?: 'CustomerListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_InviteListResult_Fragment = {
  __typename?: 'InviteListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_InvoiceListResult_Fragment = {
  __typename?: 'InvoiceListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_ProfileListResult_Fragment = {
  __typename?: 'ProfileListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_QuoteResult_Fragment = {
  __typename?: 'QuoteResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_QuoteTemplateResult_Fragment = {
  __typename?: 'QuoteTemplateResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_ShopAddressListResult_Fragment = {
  __typename?: 'ShopAddressListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_ShopAddressServiceListResult_Fragment = {
  __typename?: 'ShopAddressServiceListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

type ListResultFragment_ShopListResult_Fragment = {
  __typename?: 'ShopListResult';
  totalCount: number;
  page: number;
  pageSize: number;
};

export type ListResultFragmentFragment =
  | ListResultFragment_CustomerListResult_Fragment
  | ListResultFragment_InviteListResult_Fragment
  | ListResultFragment_InvoiceListResult_Fragment
  | ListResultFragment_ProfileListResult_Fragment
  | ListResultFragment_QuoteResult_Fragment
  | ListResultFragment_QuoteTemplateResult_Fragment
  | ListResultFragment_ShopAddressListResult_Fragment
  | ListResultFragment_ShopAddressServiceListResult_Fragment
  | ListResultFragment_ShopListResult_Fragment;

export type AddPaymentMethodMutationVariables = Exact<{
  input: ShopAddPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = {
  __typename?: 'Mutation';
  addPaymentMethod?: { __typename?: 'Shop'; _id: string } | null;
};

export type CancelMyAppointmentByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  cancelReason: Scalars['String']['input'];
}>;

export type CancelMyAppointmentByIdMutation = {
  __typename?: 'Mutation';
  cancelMyAppointmentById?: boolean | null;
};

export type CancelSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?: boolean | null;
};

export type CreateMyCalendarEventMutationVariables = Exact<{
  input: CreateOrUpdateCalendarEventInput;
}>;

export type CreateMyCalendarEventMutation = {
  __typename?: 'Mutation';
  createMyCalendarEvent?: { __typename?: 'CalendarEvent'; _id: string } | null;
};

export type CreateMyQuoteTemplateMutationVariables = Exact<{
  input: WriteMyQuoteTemplateInput;
}>;

export type CreateMyQuoteTemplateMutation = {
  __typename?: 'Mutation';
  createMyQuoteTemplate: { __typename?: 'QuoteTemplate'; _id: string };
};

export type CreateMyShopAddressMutationVariables = Exact<{
  input: CreateMyShopAddressInput;
}>;

export type CreateMyShopAddressMutation = {
  __typename?: 'Mutation';
  createMyShopAddress: { __typename?: 'ShopAddress'; _id: string };
};

export type CreateMyShopAddressServiceMutationVariables = Exact<{
  input: CreateMyShopAddressServiceInput;
}>;

export type CreateMyShopAddressServiceMutation = {
  __typename?: 'Mutation';
  createMyShopAddressService: {
    __typename?: 'ShopAddressService';
    _id: string;
  };
};

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;

export type CreateSubscriptionMutation = {
  __typename?: 'Mutation';
  createSubscription?: string | null;
};

export type DeleteMyOpenSlotByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type DeleteMyOpenSlotByIdMutation = {
  __typename?: 'Mutation';
  deleteMyOpenSlotById: boolean;
};

export type DeleteMyQuoteTemplateByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type DeleteMyQuoteTemplateByIdMutation = {
  __typename?: 'Mutation';
  deleteMyQuoteTemplateById: number;
};

export type DeleteMyShopAddressByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type DeleteMyShopAddressByIdMutation = {
  __typename?: 'Mutation';
  deleteMyShopAddressById: number;
};

export type DeleteMyShopAddressServiceByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type DeleteMyShopAddressServiceByIdMutation = {
  __typename?: 'Mutation';
  deleteMyShopAddressServiceById: number;
};

export type DenyQuoteByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  deniedReason: Scalars['String']['input'];
}>;

export type DenyQuoteByIdMutation = {
  __typename?: 'Mutation';
  denyQuoteById?: { __typename?: 'Quote'; _id: string } | null;
};

export type InviteEmployeeMutationVariables = Exact<{
  input: InviteMyEmployeeInput;
}>;

export type InviteEmployeeMutation = {
  __typename?: 'Mutation';
  inviteMyEmployee: string;
};

export type UpdateEmployeeRoleByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: UpdateMyEmployeeRoleByIdInput;
}>;

export type UpdateEmployeeRoleByIdMutation = {
  __typename?: 'Mutation';
  updateMyEmployeeRoleById?: { __typename?: 'Profile'; _id: string } | null;
};

export type UpdateMyCalendarEventMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: CreateOrUpdateCalendarEventInput;
}>;

export type UpdateMyCalendarEventMutation = {
  __typename?: 'Mutation';
  updateMyCalendarEvent?: { __typename?: 'CalendarEvent'; _id: string } | null;
};

export type UpdateMyDevicesTokenMutationVariables = Exact<{
  input: UpdateMyDevicesTokenInput;
}>;

export type UpdateMyDevicesTokenMutation = {
  __typename?: 'Mutation';
  updateMyDevicesToken?: { __typename?: 'Profile'; _id: string } | null;
};

export type UpdateMyMediaMutationVariables = Exact<{
  input: UpdateMyMediaInput;
}>;

export type UpdateMyMediaMutation = {
  __typename?: 'Mutation';
  updateMyMedia: {
    __typename?: 'Shop';
    banner?: {
      __typename?: 'Picture';
      small?: string | null;
      medium?: string | null;
      large?: string | null;
    } | null;
    profilePicture?: {
      __typename?: 'Picture';
      small?: string | null;
      medium?: string | null;
      large?: string | null;
    } | null;
  };
};

export type UpdateMyNotificationsReadMutationVariables = Exact<{
  _ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateMyNotificationsReadMutation = {
  __typename?: 'Mutation';
  updateMyNotificationsRead: boolean;
};

export type UpdateMyProfileMutationVariables = Exact<{
  input: UpdateMyProfileInput;
}>;

export type UpdateMyProfileMutation = {
  __typename?: 'Mutation';
  updateMyProfile?: { __typename?: 'Profile'; _id: string } | null;
};

export type UpdateMyQuoteByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: UpdateMyQuoteByIdInput;
}>;

export type UpdateMyQuoteByIdMutation = {
  __typename?: 'Mutation';
  updateMyQuoteById: { __typename?: 'Quote'; _id: string };
};

export type UpdateMyQuoteTemplateMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: WriteMyQuoteTemplateInput;
}>;

export type UpdateMyQuoteTemplateMutation = {
  __typename?: 'Mutation';
  updateMyQuoteTemplate: { __typename?: 'QuoteTemplate'; _id: string };
};

export type UpdateMyShopAddressByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: UpdateMyShopAddressByIdInput;
}>;

export type UpdateMyShopAddressByIdMutation = {
  __typename?: 'Mutation';
  updateMyShopAddressById?: {
    __typename?: 'ShopAddress';
    _id: string;
    fullAddress: string;
    name?: string | null;
    openingHours?: {
      __typename?: 'OpeningHours';
      monday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      tuesday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      wednesday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      thursday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      friday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      saturday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
      sunday?: {
        __typename?: 'DayOpeningHour';
        id: number;
        type: OpeningDayType;
        start?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateMyShopAddressServiceByIdMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  input: UpdateMyShopAddressServiceByIdInput;
}>;

export type UpdateMyShopAddressServiceByIdMutation = {
  __typename?: 'Mutation';
  updateMyShopAddressServiceById?: {
    __typename?: 'ShopAddressService';
    _id: string;
  } | null;
};

export type UpdateMyShopNameAndBioMutationVariables = Exact<{
  input: UpdateMyShopNameAndBioInput;
}>;

export type UpdateMyShopNameAndBioMutation = {
  __typename?: 'Mutation';
  updateMyShopNameAndBio?: { __typename?: 'Shop'; _id: string } | null;
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutation = {
  __typename?: 'Mutation';
  updateSubscription?: string | null;
};

export type GetAllMyCustomersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllMyCustomersQuery = {
  __typename?: 'Query';
  myShop?: {
    __typename?: 'Shop';
    customers?: Array<{
      __typename?: 'Customer';
      _id: string;
      name: string;
      phone: string;
    }> | null;
  } | null;
};

export type GetAllMyEmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllMyEmployeesQuery = {
  __typename?: 'Query';
  myShop?: {
    __typename?: 'Shop';
    employees?: Array<{
      __typename?: 'Profile';
      _id: string;
      email: string;
      username?: string | null;
    }> | null;
  } | null;
};

export type GetAllMyShopAddressServicesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllMyShopAddressServicesQuery = {
  __typename?: 'Query';
  myShop?: {
    __typename?: 'Shop';
    shopAddressServices?: Array<{
      __typename?: 'ShopAddressService';
      _id: string;
      name: string;
      price: number;
      deposit?: number | null;
      currency: CurrencyCode;
      shopAddressIds?: Array<string> | null;
      quoteTemplateId?: string | null;
      duration: { __typename?: 'HourMinute'; hour: number; min: number };
    }> | null;
  } | null;
};

export type GetAllMyShopAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllMyShopAddressesQuery = {
  __typename?: 'Query';
  myShop?: {
    __typename?: 'Shop';
    shopAddresses?: Array<{
      __typename?: 'ShopAddress';
      _id: string;
      name?: string | null;
      fullAddress: string;
    }> | null;
  } | null;
};

export type GetMyCalendarEventDateRangeQueryVariables = Exact<{
  shopAddressIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  employeeIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  types?: InputMaybe<Array<CalendarEventType> | CalendarEventType>;
}>;

export type GetMyCalendarEventDateRangeQuery = {
  __typename?: 'Query';
  myCalendarEventsByDateRange?: Array<{
    __typename?: 'CalendarEvent';
    _id: string;
    calendarEventIntentId?: string | null;
    type: CalendarEventType;
    shopId: string;
    deleted?: boolean | null;
    dateRange: { __typename?: 'DateRange'; from: string; to: string };
    booking?: {
      __typename?: 'CalendarEventBooking';
      currency?: CurrencyCode | null;
      price: number;
      state: CalendarEventBookingState;
      deposit?: number | null;
      withPayment: boolean;
      customer: {
        __typename?: 'Customer';
        _id: string;
        name: string;
        phone: string;
        shopId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      shopAddressService: {
        __typename?: 'ShopAddressService';
        _id: string;
        name: string;
        availableSeats: number;
        category: ShopAddressServiceCategory;
        currency: CurrencyCode;
        employeeIds?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        price: number;
        shopAddressIds?: Array<string> | null;
        shopId: string;
        duration: { __typename?: 'HourMinute'; hour: number; min: number };
      };
      paymentInfo?: {
        __typename?: 'PaymentInfo';
        paymentChoice: PaymentChoice;
      } | null;
    } | null;
    employee?: {
      __typename?: 'Profile';
      _id: string;
      email: string;
      username?: string | null;
    } | null;
  }> | null;
};

export type GetMyEmployeesQueryVariables = Exact<{
  filter?: InputMaybe<FilterProfileCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<ProfileSort>;
}>;

export type GetMyEmployeesQuery = {
  __typename?: 'Query';
  employees?: {
    __typename?: 'ProfileListResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'Profile';
      _id: string;
      email: string;
      role: ProfileRole;
      createdAt?: string | null;
      username?: string | null;
    }>;
  } | null;
};

export type GetMyInvitesQueryVariables = Exact<{
  filter?: InputMaybe<FilterInviteCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<InviteSort>;
}>;

export type GetMyInvitesQuery = {
  __typename?: 'Query';
  invites?: {
    __typename?: 'InviteListResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'Invite';
      _id: string;
      email: string;
      state: InviteState;
      expiresAt: string;
      role: ProfileRole;
    }>;
  } | null;
};

export type GetMyInvoicesQueryVariables = Exact<{
  filter?: InputMaybe<FilterInvoiceCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<InvoiceSort>;
}>;

export type GetMyInvoicesQuery = {
  __typename?: 'Query';
  invoices?: {
    __typename?: 'InvoiceListResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'Invoice';
      _id: string;
      createdAt?: string | null;
      pdfUrl: string;
      status: InvoiceStatus;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        paymentMethod?: {
          __typename?: 'PaymentMethod';
          card: { __typename?: 'Card'; brand?: string | null; last4: string };
        } | null;
        product?: {
          __typename?: 'Product';
          name?: SubscriptionPlanType | null;
          price?: {
            __typename?: 'Price';
            currency: string;
            recurringInterval?: Interval | null;
            unitAmount: number;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type GetMyNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyNotificationsQuery = {
  __typename?: 'Query';
  myNotifications?: Array<{
    __typename?: 'Notification';
    _id: string;
    body: string;
    priority?: NotificationPriority | null;
    title: string;
  }> | null;
};

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'Profile';
    _id: string;
    email: string;
    shopId?: string | null;
    sub: string;
    username?: string | null;
    permissions?: Array<string> | null;
  } | null;
};

export type PaymentMethodFragmentFragment = {
  __typename?: 'PaymentMethod';
  id: string;
  card: {
    __typename?: 'Card';
    brand?: string | null;
    expirationMonth: number;
    expirationYear: number;
    last4: string;
    name: string;
  };
};

export type GetMyProfileDetailQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileDetailQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'Profile';
    email: string;
    shopId?: string | null;
    username?: string | null;
    shop?: {
      __typename?: 'Shop';
      defaultPaymentMethod?: {
        __typename?: 'PaymentMethod';
        id: string;
        card: {
          __typename?: 'Card';
          brand?: string | null;
          expirationMonth: number;
          expirationYear: number;
          last4: string;
          name: string;
        };
      } | null;
      allPaymentMethods?: Array<{
        __typename?: 'PaymentMethod';
        id: string;
        card: {
          __typename?: 'Card';
          brand?: string | null;
          expirationMonth: number;
          expirationYear: number;
          last4: string;
          name: string;
        };
      }> | null;
      subscriptionPlan?: {
        __typename?: 'SubscriptionPlan';
        subscriptionId?: string | null;
        name?: string | null;
        status?: SubscriptionPlanStatus | null;
        product?: {
          __typename?: 'Product';
          id: string;
          name?: SubscriptionPlanType | null;
          price?: {
            __typename?: 'Price';
            id: string;
            unitAmount: number;
            recurringInterval?: Interval | null;
            currency: string;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMyQuoteTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<FilterQuoteTemplatesCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<QuoteTemplateSort>;
}>;

export type GetMyQuoteTemplatesQuery = {
  __typename?: 'Query';
  myQuoteTemplates?: {
    __typename?: 'QuoteTemplateResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'QuoteTemplate';
      _id: string;
      name: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      elements: Array<{
        __typename?: 'QuoteTemplateElement';
        type: QuoteTemplateElementType;
        label: string;
        required: boolean;
        choices?: Array<{
          __typename?: 'QuoteTemplateChoice';
          value: string;
        }> | null;
      }>;
    }>;
  } | null;
};

export type GetMyQuotesQueryVariables = Exact<{
  filter?: InputMaybe<FilterQuotesCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<QuoteSort>;
}>;

export type GetMyQuotesQuery = {
  __typename?: 'Query';
  myQuotes?: {
    __typename?: 'QuoteResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'Quote';
      _id: string;
      shopId: string;
      state: QuoteState;
      price?: number | null;
      comments?: string | null;
      deniedReason?: string | null;
      deposit?: number | null;
      reference: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      customer: { __typename?: 'Customer'; name: string; phone: string };
      duration?: {
        __typename?: 'HourMinute';
        hour: number;
        min: number;
      } | null;
      answers: Array<{
        __typename?: 'QuoteAnswer';
        label: string;
        required: boolean;
        type: QuoteTemplateElementType;
        answerArrayString?: Array<string> | null;
        answerString?: string | null;
        answerBoolean?: boolean | null;
        choices?: Array<{
          __typename?: 'QuoteTemplateChoice';
          value: string;
        }> | null;
      }>;
      shopAddress: {
        __typename?: 'ShopAddress';
        name?: string | null;
        fullAddress: string;
      };
      shopAddressService: {
        __typename?: 'ShopAddressService';
        _id: string;
        category: ShopAddressServiceCategory;
        name: string;
      };
    }>;
  } | null;
};

export type GetMyShopQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyShopQuery = {
  __typename?: 'Query';
  myShop?: {
    __typename?: 'Shop';
    _id: string;
    bio?: string | null;
    name: string;
    phoneNumber: string;
    profilePicture?: {
      __typename?: 'Picture';
      small?: string | null;
      medium?: string | null;
      large?: string | null;
    } | null;
    banner?: {
      __typename?: 'Picture';
      small?: string | null;
      medium?: string | null;
      large?: string | null;
    } | null;
  } | null;
};

export type GetMyShopAddressServicesQueryVariables = Exact<{
  filter?: InputMaybe<FilterShopAddressServiceCriteria>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<ShopAddressServiceSort>;
}>;

export type GetMyShopAddressServicesQuery = {
  __typename?: 'Query';
  myShopAddressServices?: {
    __typename?: 'ShopAddressServiceListResult';
    totalCount: number;
    page: number;
    pageSize: number;
    items: Array<{
      __typename?: 'ShopAddressService';
      _id: string;
      name: string;
      description?: string | null;
      price: number;
      deposit?: number | null;
      currency: CurrencyCode;
      category: ShopAddressServiceCategory;
      shopAddressIds?: Array<string> | null;
      availableSeats: number;
      employeeIds?: Array<string> | null;
      startDate?: string | null;
      endDate?: string | null;
      quoteTemplateId?: string | null;
      duration: { __typename?: 'HourMinute'; hour: number; min: number };
    }>;
  } | null;
};

export type GetMyShopAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyShopAddressesQuery = {
  __typename?: 'Query';
  myShopAddresses?: {
    __typename?: 'ShopAddressListResult';
    totalCount: number;
    items: Array<{
      __typename?: 'ShopAddress';
      _id: string;
      name?: string | null;
      fullAddress: string;
      postalCode?: string | null;
      country?: string | null;
      region?: string | null;
      streetNumber?: string | null;
      location?: { __typename?: 'GeoPoint'; lat: number; lng: number } | null;
      openingHours?: {
        __typename?: 'OpeningHours';
        monday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        tuesday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        wednesday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        thursday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        friday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        saturday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
        sunday?: {
          __typename?: 'DayOpeningHour';
          id: number;
          type: OpeningDayType;
          start?: {
            __typename?: 'HourMinute';
            hour: number;
            min: number;
          } | null;
          end?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetSubscriptionProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSubscriptionProductsQuery = {
  __typename?: 'Query';
  subscriptionProducts: Array<{
    __typename?: 'Product';
    description?: string | null;
    id: string;
    name?: SubscriptionPlanType | null;
    price?: {
      __typename?: 'Price';
      currency: string;
      id: string;
      recurringInterval?: Interval | null;
      unitAmount: number;
    } | null;
  }>;
};

export type OnMyCalendarEventUpdatedSubscriptionVariables = Exact<{
  shopId: Scalars['ID']['input'];
}>;

export type OnMyCalendarEventUpdatedSubscription = {
  __typename?: 'Subscription';
  onMyCalendarEventUpdated?: {
    __typename?: 'CalendarEvent';
    _id: string;
    calendarEventIntentId?: string | null;
    type: CalendarEventType;
    shopId: string;
    deleted?: boolean | null;
    dateRange: { __typename?: 'DateRange'; from: string; to: string };
    booking?: {
      __typename?: 'CalendarEventBooking';
      currency?: CurrencyCode | null;
      price: number;
      state: CalendarEventBookingState;
      deposit?: number | null;
      withPayment: boolean;
      customer: {
        __typename?: 'Customer';
        _id: string;
        name: string;
        phone: string;
        shopId: string;
        createdAt?: string | null;
        updatedAt?: string | null;
      };
      shopAddressService: {
        __typename?: 'ShopAddressService';
        _id: string;
        name: string;
        availableSeats: number;
        category: ShopAddressServiceCategory;
        currency: CurrencyCode;
        employeeIds?: Array<string> | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        price: number;
        shopAddressIds?: Array<string> | null;
        shopId: string;
        duration: { __typename?: 'HourMinute'; hour: number; min: number };
      };
      paymentInfo?: {
        __typename?: 'PaymentInfo';
        paymentChoice: PaymentChoice;
      } | null;
    } | null;
    employee?: {
      __typename?: 'Profile';
      _id: string;
      email: string;
      username?: string | null;
    } | null;
  } | null;
};

export type OnProfileUpdatedByIdSubscriptionVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type OnProfileUpdatedByIdSubscription = {
  __typename?: 'Subscription';
  onProfileUpdatedById?: {
    __typename?: 'Profile';
    _id: string;
    email: string;
    shopId?: string | null;
    sub: string;
    username?: string | null;
    permissions?: Array<string> | null;
  } | null;
};

export type OnQuoteUpdatedSubscriptionVariables = Exact<{
  shopId: Scalars['ID']['input'];
}>;

export type OnQuoteUpdatedSubscription = {
  __typename?: 'Subscription';
  onQuoteUpdated?: {
    __typename?: 'Quote';
    _id: string;
    shopId: string;
    state: QuoteState;
    price?: number | null;
    comments?: string | null;
    deniedReason?: string | null;
    deposit?: number | null;
    reference: string;
    createdAt?: string | null;
    updatedAt?: string | null;
    customer: { __typename?: 'Customer'; name: string; phone: string };
    duration?: { __typename?: 'HourMinute'; hour: number; min: number } | null;
    answers: Array<{
      __typename?: 'QuoteAnswer';
      label: string;
      required: boolean;
      type: QuoteTemplateElementType;
      answerArrayString?: Array<string> | null;
      answerString?: string | null;
      answerBoolean?: boolean | null;
      choices?: Array<{
        __typename?: 'QuoteTemplateChoice';
        value: string;
      }> | null;
    }>;
    shopAddress: {
      __typename?: 'ShopAddress';
      name?: string | null;
      fullAddress: string;
    };
    shopAddressService: {
      __typename?: 'ShopAddressService';
      _id: string;
      category: ShopAddressServiceCategory;
      name: string;
    };
  } | null;
};

export const ListResultFragmentFragmentDoc = `
    fragment ListResultFragment on ListResult {
  totalCount
  page
  pageSize
}
    `;
export const PaymentMethodFragmentFragmentDoc = `
    fragment PaymentMethodFragment on PaymentMethod {
  card {
    brand
    expirationMonth
    expirationYear
    last4
    name
  }
  id
}
    `;
export const AddPaymentMethodDocument = `
    mutation AddPaymentMethod($input: ShopAddPaymentMethodInput!) {
  addPaymentMethod(input: $input) {
    _id
  }
}
    `;
export const CancelMyAppointmentByIdDocument = `
    mutation CancelMyAppointmentById($_id: ID!, $cancelReason: String!) {
  cancelMyAppointmentById(_id: $_id, cancelReason: $cancelReason)
}
    `;
export const CancelSubscriptionDocument = `
    mutation CancelSubscription {
  cancelSubscription
}
    `;
export const CreateMyCalendarEventDocument = `
    mutation CreateMyCalendarEvent($input: CreateOrUpdateCalendarEventInput!) {
  createMyCalendarEvent(input: $input) {
    _id
  }
}
    `;
export const CreateMyQuoteTemplateDocument = `
    mutation CreateMyQuoteTemplate($input: WriteMyQuoteTemplateInput!) {
  createMyQuoteTemplate(input: $input) {
    _id
  }
}
    `;
export const CreateMyShopAddressDocument = `
    mutation CreateMyShopAddress($input: CreateMyShopAddressInput!) {
  createMyShopAddress(input: $input) {
    _id
  }
}
    `;
export const CreateMyShopAddressServiceDocument = `
    mutation CreateMyShopAddressService($input: CreateMyShopAddressServiceInput!) {
  createMyShopAddressService(input: $input) {
    _id
  }
}
    `;
export const CreateSubscriptionDocument = `
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input)
}
    `;
export const DeleteMyOpenSlotByIdDocument = `
    mutation DeleteMyOpenSlotById($_id: ID!) {
  deleteMyOpenSlotById(_id: $_id)
}
    `;
export const DeleteMyQuoteTemplateByIdDocument = `
    mutation DeleteMyQuoteTemplateById($_id: ID!) {
  deleteMyQuoteTemplateById(_id: $_id)
}
    `;
export const DeleteMyShopAddressByIdDocument = `
    mutation DeleteMyShopAddressById($_id: ID!) {
  deleteMyShopAddressById(_id: $_id)
}
    `;
export const DeleteMyShopAddressServiceByIdDocument = `
    mutation DeleteMyShopAddressServiceById($_id: ID!) {
  deleteMyShopAddressServiceById(_id: $_id)
}
    `;
export const DenyQuoteByIdDocument = `
    mutation DenyQuoteById($_id: ID!, $deniedReason: String!) {
  denyQuoteById(_id: $_id, deniedReason: $deniedReason) {
    _id
  }
}
    `;
export const InviteEmployeeDocument = `
    mutation InviteEmployee($input: InviteMyEmployeeInput!) {
  inviteMyEmployee(input: $input)
}
    `;
export const UpdateEmployeeRoleByIdDocument = `
    mutation UpdateEmployeeRoleById($_id: ID!, $input: UpdateMyEmployeeRoleByIdInput!) {
  updateMyEmployeeRoleById(_id: $_id, input: $input) {
    _id
  }
}
    `;
export const UpdateMyCalendarEventDocument = `
    mutation UpdateMyCalendarEvent($_id: ID!, $input: CreateOrUpdateCalendarEventInput!) {
  updateMyCalendarEvent(_id: $_id, input: $input) {
    _id
  }
}
    `;
export const UpdateMyDevicesTokenDocument = `
    mutation UpdateMyDevicesToken($input: UpdateMyDevicesTokenInput!) {
  updateMyDevicesToken(input: $input) {
    _id
  }
}
    `;
export const UpdateMyMediaDocument = `
    mutation UpdateMyMedia($input: UpdateMyMediaInput!) {
  updateMyMedia(input: $input) {
    banner {
      small
      medium
      large
    }
    profilePicture {
      small
      medium
      large
    }
  }
}
    `;
export const UpdateMyNotificationsReadDocument = `
    mutation UpdateMyNotificationsRead($_ids: [ID!]!) {
  updateMyNotificationsRead(_ids: $_ids)
}
    `;
export const UpdateMyProfileDocument = `
    mutation UpdateMyProfile($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    _id
  }
}
    `;
export const UpdateMyQuoteByIdDocument = `
    mutation UpdateMyQuoteById($_id: ID!, $input: UpdateMyQuoteByIdInput!) {
  updateMyQuoteById(_id: $_id, input: $input) {
    _id
  }
}
    `;
export const UpdateMyQuoteTemplateDocument = `
    mutation UpdateMyQuoteTemplate($_id: ID!, $input: WriteMyQuoteTemplateInput!) {
  updateMyQuoteTemplate(_id: $_id, input: $input) {
    _id
  }
}
    `;
export const UpdateMyShopAddressByIdDocument = `
    mutation UpdateMyShopAddressById($_id: ID!, $input: UpdateMyShopAddressByIdInput!) {
  updateMyShopAddressById(_id: $_id, input: $input) {
    _id
    fullAddress
    name
    openingHours {
      monday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      tuesday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      wednesday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      thursday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      friday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      saturday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
      sunday {
        id
        type
        start {
          hour
          min
        }
        end {
          hour
          min
        }
      }
    }
  }
}
    `;
export const UpdateMyShopAddressServiceByIdDocument = `
    mutation UpdateMyShopAddressServiceById($_id: ID!, $input: UpdateMyShopAddressServiceByIdInput!) {
  updateMyShopAddressServiceById(_id: $_id, input: $input) {
    _id
  }
}
    `;
export const UpdateMyShopNameAndBioDocument = `
    mutation UpdateMyShopNameAndBio($input: UpdateMyShopNameAndBioInput!) {
  updateMyShopNameAndBio(input: $input) {
    _id
  }
}
    `;
export const UpdateSubscriptionDocument = `
    mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input)
}
    `;
export const GetAllMyCustomersDocument = `
    query GetAllMyCustomers {
  myShop {
    customers {
      _id
      name
      phone
    }
  }
}
    `;
export const GetAllMyEmployeesDocument = `
    query GetAllMyEmployees {
  myShop {
    employees {
      _id
      email
      username
    }
  }
}
    `;
export const GetAllMyShopAddressServicesDocument = `
    query GetAllMyShopAddressServices {
  myShop {
    shopAddressServices {
      _id
      name
      price
      deposit
      duration {
        hour
        min
      }
      currency
      shopAddressIds
      quoteTemplateId
    }
  }
}
    `;
export const GetAllMyShopAddressesDocument = `
    query GetAllMyShopAddresses {
  myShop {
    shopAddresses {
      _id
      name
      fullAddress
    }
  }
}
    `;
export const GetMyCalendarEventDateRangeDocument = `
    query GetMyCalendarEventDateRange($shopAddressIds: [ID!], $employeeIds: [ID!], $from: String!, $to: String!, $types: [CalendarEventType!]) {
  myCalendarEventsByDateRange(
    shopAddressIds: $shopAddressIds
    employeeIds: $employeeIds
    from: $from
    to: $to
    types: $types
  ) {
    _id
    calendarEventIntentId
    type
    shopId
    dateRange {
      from
      to
    }
    deleted
    booking {
      currency
      price
      customer {
        _id
        name
        phone
        shopId
        createdAt
        updatedAt
      }
      shopAddressService {
        _id
        name
        availableSeats
        category
        currency
        duration {
          hour
          min
        }
        employeeIds
        createdAt
        updatedAt
        price
        shopAddressIds
        shopId
      }
      state
      deposit
      withPayment
      paymentInfo {
        paymentChoice
      }
    }
    employee {
      _id
      email
      username
    }
  }
}
    `;
export const GetMyEmployeesDocument = `
    query GetMyEmployees($filter: FilterProfileCriteria, $pagination: Pagination, $sort: ProfileSort) {
  employees: myEmployees(filter: $filter, pagination: $pagination, sort: $sort) {
    ...ListResultFragment
    items {
      _id
      email
      role
      createdAt
      username
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyInvitesDocument = `
    query GetMyInvites($filter: FilterInviteCriteria, $pagination: Pagination, $sort: InviteSort) {
  invites: myEmployeeInvites(
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    ...ListResultFragment
    items {
      _id
      email
      state
      expiresAt
      role
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyInvoicesDocument = `
    query GetMyInvoices($filter: FilterInvoiceCriteria, $pagination: Pagination, $sort: InvoiceSort) {
  invoices: myInvoices(filter: $filter, pagination: $pagination, sort: $sort) {
    ...ListResultFragment
    items {
      _id
      createdAt
      pdfUrl
      status
      subscriptionPlan {
        paymentMethod {
          card {
            brand
            last4
          }
        }
        product {
          name
          price {
            currency
            recurringInterval
            unitAmount
          }
        }
      }
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyNotificationsDocument = `
    query GetMyNotifications {
  myNotifications {
    _id
    body
    priority
    title
  }
}
    `;
export const GetMyProfileDocument = `
    query GetMyProfile {
  me {
    _id
    email
    shopId
    sub
    username
    permissions
  }
}
    `;
export const GetMyProfileDetailDocument = `
    query GetMyProfileDetail {
  me {
    email
    shopId
    username
    shop {
      defaultPaymentMethod {
        ...PaymentMethodFragment
      }
      allPaymentMethods {
        ...PaymentMethodFragment
      }
      subscriptionPlan {
        subscriptionId
        name
        status
        product {
          id
          name
          price {
            id
            unitAmount
            recurringInterval
            currency
          }
        }
      }
    }
  }
}
    ${PaymentMethodFragmentFragmentDoc}`;
export const GetMyQuoteTemplatesDocument = `
    query GetMyQuoteTemplates($filter: FilterQuoteTemplatesCriteria, $pagination: Pagination, $sort: QuoteTemplateSort) {
  myQuoteTemplates(filter: $filter, pagination: $pagination, sort: $sort) {
    ...ListResultFragment
    items {
      _id
      name
      elements {
        type
        label
        choices {
          value
        }
        required
      }
      createdAt
      updatedAt
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyQuotesDocument = `
    query GetMyQuotes($filter: FilterQuotesCriteria, $pagination: Pagination, $sort: QuoteSort) {
  myQuotes(filter: $filter, pagination: $pagination, sort: $sort) {
    ...ListResultFragment
    items {
      _id
      customer {
        name
        phone
      }
      shopId
      state
      price
      comments
      deniedReason
      deposit
      reference
      duration {
        hour
        min
      }
      answers {
        choices {
          value
        }
        label
        required
        type
        answerArrayString
        answerString
        answerBoolean
      }
      shopAddress {
        name
        fullAddress
      }
      shopAddressService {
        _id
        category
        name
      }
      createdAt
      updatedAt
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyShopDocument = `
    query GetMyShop {
  myShop {
    _id
    bio
    name
    phoneNumber
    profilePicture {
      small
      medium
      large
    }
    banner {
      small
      medium
      large
    }
  }
}
    `;
export const GetMyShopAddressServicesDocument = `
    query GetMyShopAddressServices($filter: FilterShopAddressServiceCriteria, $pagination: Pagination, $sort: ShopAddressServiceSort) {
  myShopAddressServices(filter: $filter, pagination: $pagination, sort: $sort) {
    ...ListResultFragment
    items {
      _id
      name
      description
      price
      deposit
      currency
      category
      shopAddressIds
      duration {
        hour
        min
      }
      availableSeats
      employeeIds
      startDate
      endDate
      quoteTemplateId
    }
  }
}
    ${ListResultFragmentFragmentDoc}`;
export const GetMyShopAddressesDocument = `
    query GetMyShopAddresses {
  myShopAddresses(pagination: {page: 1, pageSize: 100}, sort: {createdAt: 1}) {
    totalCount
    items {
      _id
      name
      fullAddress
      postalCode
      country
      region
      streetNumber
      location {
        lat
        lng
      }
      openingHours {
        monday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        tuesday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        wednesday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        thursday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        friday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        saturday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
        sunday {
          id
          type
          start {
            hour
            min
          }
          end {
            hour
            min
          }
        }
      }
    }
  }
}
    `;
export const GetSubscriptionProductsDocument = `
    query GetSubscriptionProducts {
  subscriptionProducts {
    description
    id
    name
    price {
      currency
      id
      recurringInterval
      unitAmount
    }
  }
}
    `;
export const OnMyCalendarEventUpdatedDocument = `
    subscription OnMyCalendarEventUpdated($shopId: ID!) {
  onMyCalendarEventUpdated(shopId: $shopId) {
    _id
    calendarEventIntentId
    type
    shopId
    dateRange {
      from
      to
    }
    deleted
    booking {
      currency
      price
      customer {
        _id
        name
        phone
        shopId
        createdAt
        updatedAt
      }
      shopAddressService {
        _id
        name
        availableSeats
        category
        currency
        duration {
          hour
          min
        }
        employeeIds
        createdAt
        updatedAt
        price
        shopAddressIds
        shopId
      }
      state
      deposit
      withPayment
      paymentInfo {
        paymentChoice
      }
    }
    employee {
      _id
      email
      username
    }
  }
}
    `;
export const OnProfileUpdatedByIdDocument = `
    subscription OnProfileUpdatedById($_id: ID!) {
  onProfileUpdatedById(_id: $_id) {
    _id
    email
    shopId
    sub
    username
    permissions
  }
}
    `;
export const OnQuoteUpdatedDocument = `
    subscription OnQuoteUpdated($shopId: ID!) {
  onQuoteUpdated(shopId: $shopId) {
    _id
    customer {
      name
      phone
    }
    shopId
    state
    price
    comments
    deniedReason
    deposit
    reference
    duration {
      hour
      min
    }
    answers {
      choices {
        value
      }
      label
      required
      type
      answerArrayString
      answerString
      answerBoolean
    }
    shopAddress {
      name
      fullAddress
    }
    shopAddressService {
      _id
      category
      name
    }
    createdAt
    updatedAt
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddPaymentMethod: build.mutation<
      AddPaymentMethodMutation,
      AddPaymentMethodMutationVariables
    >({
      query: (variables) => ({ document: AddPaymentMethodDocument, variables })
    }),
    CancelMyAppointmentById: build.mutation<
      CancelMyAppointmentByIdMutation,
      CancelMyAppointmentByIdMutationVariables
    >({
      query: (variables) => ({
        document: CancelMyAppointmentByIdDocument,
        variables
      })
    }),
    CancelSubscription: build.mutation<
      CancelSubscriptionMutation,
      CancelSubscriptionMutationVariables | void
    >({
      query: (variables) => ({
        document: CancelSubscriptionDocument,
        variables
      })
    }),
    CreateMyCalendarEvent: build.mutation<
      CreateMyCalendarEventMutation,
      CreateMyCalendarEventMutationVariables
    >({
      query: (variables) => ({
        document: CreateMyCalendarEventDocument,
        variables
      })
    }),
    CreateMyQuoteTemplate: build.mutation<
      CreateMyQuoteTemplateMutation,
      CreateMyQuoteTemplateMutationVariables
    >({
      query: (variables) => ({
        document: CreateMyQuoteTemplateDocument,
        variables
      })
    }),
    CreateMyShopAddress: build.mutation<
      CreateMyShopAddressMutation,
      CreateMyShopAddressMutationVariables
    >({
      query: (variables) => ({
        document: CreateMyShopAddressDocument,
        variables
      })
    }),
    CreateMyShopAddressService: build.mutation<
      CreateMyShopAddressServiceMutation,
      CreateMyShopAddressServiceMutationVariables
    >({
      query: (variables) => ({
        document: CreateMyShopAddressServiceDocument,
        variables
      })
    }),
    CreateSubscription: build.mutation<
      CreateSubscriptionMutation,
      CreateSubscriptionMutationVariables
    >({
      query: (variables) => ({
        document: CreateSubscriptionDocument,
        variables
      })
    }),
    DeleteMyOpenSlotById: build.mutation<
      DeleteMyOpenSlotByIdMutation,
      DeleteMyOpenSlotByIdMutationVariables
    >({
      query: (variables) => ({
        document: DeleteMyOpenSlotByIdDocument,
        variables
      })
    }),
    DeleteMyQuoteTemplateById: build.mutation<
      DeleteMyQuoteTemplateByIdMutation,
      DeleteMyQuoteTemplateByIdMutationVariables
    >({
      query: (variables) => ({
        document: DeleteMyQuoteTemplateByIdDocument,
        variables
      })
    }),
    DeleteMyShopAddressById: build.mutation<
      DeleteMyShopAddressByIdMutation,
      DeleteMyShopAddressByIdMutationVariables
    >({
      query: (variables) => ({
        document: DeleteMyShopAddressByIdDocument,
        variables
      })
    }),
    DeleteMyShopAddressServiceById: build.mutation<
      DeleteMyShopAddressServiceByIdMutation,
      DeleteMyShopAddressServiceByIdMutationVariables
    >({
      query: (variables) => ({
        document: DeleteMyShopAddressServiceByIdDocument,
        variables
      })
    }),
    DenyQuoteById: build.mutation<
      DenyQuoteByIdMutation,
      DenyQuoteByIdMutationVariables
    >({
      query: (variables) => ({ document: DenyQuoteByIdDocument, variables })
    }),
    InviteEmployee: build.mutation<
      InviteEmployeeMutation,
      InviteEmployeeMutationVariables
    >({
      query: (variables) => ({ document: InviteEmployeeDocument, variables })
    }),
    UpdateEmployeeRoleById: build.mutation<
      UpdateEmployeeRoleByIdMutation,
      UpdateEmployeeRoleByIdMutationVariables
    >({
      query: (variables) => ({
        document: UpdateEmployeeRoleByIdDocument,
        variables
      })
    }),
    UpdateMyCalendarEvent: build.mutation<
      UpdateMyCalendarEventMutation,
      UpdateMyCalendarEventMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyCalendarEventDocument,
        variables
      })
    }),
    UpdateMyDevicesToken: build.mutation<
      UpdateMyDevicesTokenMutation,
      UpdateMyDevicesTokenMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyDevicesTokenDocument,
        variables
      })
    }),
    UpdateMyMedia: build.mutation<
      UpdateMyMediaMutation,
      UpdateMyMediaMutationVariables
    >({
      query: (variables) => ({ document: UpdateMyMediaDocument, variables })
    }),
    UpdateMyNotificationsRead: build.mutation<
      UpdateMyNotificationsReadMutation,
      UpdateMyNotificationsReadMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyNotificationsReadDocument,
        variables
      })
    }),
    UpdateMyProfile: build.mutation<
      UpdateMyProfileMutation,
      UpdateMyProfileMutationVariables
    >({
      query: (variables) => ({ document: UpdateMyProfileDocument, variables })
    }),
    UpdateMyQuoteById: build.mutation<
      UpdateMyQuoteByIdMutation,
      UpdateMyQuoteByIdMutationVariables
    >({
      query: (variables) => ({ document: UpdateMyQuoteByIdDocument, variables })
    }),
    UpdateMyQuoteTemplate: build.mutation<
      UpdateMyQuoteTemplateMutation,
      UpdateMyQuoteTemplateMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyQuoteTemplateDocument,
        variables
      })
    }),
    UpdateMyShopAddressById: build.mutation<
      UpdateMyShopAddressByIdMutation,
      UpdateMyShopAddressByIdMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyShopAddressByIdDocument,
        variables
      })
    }),
    UpdateMyShopAddressServiceById: build.mutation<
      UpdateMyShopAddressServiceByIdMutation,
      UpdateMyShopAddressServiceByIdMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyShopAddressServiceByIdDocument,
        variables
      })
    }),
    UpdateMyShopNameAndBio: build.mutation<
      UpdateMyShopNameAndBioMutation,
      UpdateMyShopNameAndBioMutationVariables
    >({
      query: (variables) => ({
        document: UpdateMyShopNameAndBioDocument,
        variables
      })
    }),
    UpdateSubscription: build.mutation<
      UpdateSubscriptionMutation,
      UpdateSubscriptionMutationVariables
    >({
      query: (variables) => ({
        document: UpdateSubscriptionDocument,
        variables
      })
    }),
    GetAllMyCustomers: build.query<
      GetAllMyCustomersQuery,
      GetAllMyCustomersQueryVariables | void
    >({
      query: (variables) => ({ document: GetAllMyCustomersDocument, variables })
    }),
    GetAllMyEmployees: build.query<
      GetAllMyEmployeesQuery,
      GetAllMyEmployeesQueryVariables | void
    >({
      query: (variables) => ({ document: GetAllMyEmployeesDocument, variables })
    }),
    GetAllMyShopAddressServices: build.query<
      GetAllMyShopAddressServicesQuery,
      GetAllMyShopAddressServicesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllMyShopAddressServicesDocument,
        variables
      })
    }),
    GetAllMyShopAddresses: build.query<
      GetAllMyShopAddressesQuery,
      GetAllMyShopAddressesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllMyShopAddressesDocument,
        variables
      })
    }),
    GetMyCalendarEventDateRange: build.query<
      GetMyCalendarEventDateRangeQuery,
      GetMyCalendarEventDateRangeQueryVariables
    >({
      query: (variables) => ({
        document: GetMyCalendarEventDateRangeDocument,
        variables
      })
    }),
    GetMyEmployees: build.query<
      GetMyEmployeesQuery,
      GetMyEmployeesQueryVariables | void
    >({
      query: (variables) => ({ document: GetMyEmployeesDocument, variables })
    }),
    GetMyInvites: build.query<
      GetMyInvitesQuery,
      GetMyInvitesQueryVariables | void
    >({
      query: (variables) => ({ document: GetMyInvitesDocument, variables })
    }),
    GetMyInvoices: build.query<
      GetMyInvoicesQuery,
      GetMyInvoicesQueryVariables | void
    >({
      query: (variables) => ({ document: GetMyInvoicesDocument, variables })
    }),
    GetMyNotifications: build.query<
      GetMyNotificationsQuery,
      GetMyNotificationsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMyNotificationsDocument,
        variables
      })
    }),
    GetMyProfile: build.query<
      GetMyProfileQuery,
      GetMyProfileQueryVariables | void
    >({
      query: (variables) => ({ document: GetMyProfileDocument, variables })
    }),
    GetMyProfileDetail: build.query<
      GetMyProfileDetailQuery,
      GetMyProfileDetailQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMyProfileDetailDocument,
        variables
      })
    }),
    GetMyQuoteTemplates: build.query<
      GetMyQuoteTemplatesQuery,
      GetMyQuoteTemplatesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMyQuoteTemplatesDocument,
        variables
      })
    }),
    GetMyQuotes: build.query<
      GetMyQuotesQuery,
      GetMyQuotesQueryVariables | void
    >({
      query: (variables) => ({ document: GetMyQuotesDocument, variables })
    }),
    GetMyShop: build.query<GetMyShopQuery, GetMyShopQueryVariables | void>({
      query: (variables) => ({ document: GetMyShopDocument, variables })
    }),
    GetMyShopAddressServices: build.query<
      GetMyShopAddressServicesQuery,
      GetMyShopAddressServicesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMyShopAddressServicesDocument,
        variables
      })
    }),
    GetMyShopAddresses: build.query<
      GetMyShopAddressesQuery,
      GetMyShopAddressesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetMyShopAddressesDocument,
        variables
      })
    }),
    GetSubscriptionProducts: build.query<
      GetSubscriptionProductsQuery,
      GetSubscriptionProductsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetSubscriptionProductsDocument,
        variables
      })
    })
  })
});

export { injectedRtkApi as api };
export const {
  useAddPaymentMethodMutation,
  useCancelMyAppointmentByIdMutation,
  useCancelSubscriptionMutation,
  useCreateMyCalendarEventMutation,
  useCreateMyQuoteTemplateMutation,
  useCreateMyShopAddressMutation,
  useCreateMyShopAddressServiceMutation,
  useCreateSubscriptionMutation,
  useDeleteMyOpenSlotByIdMutation,
  useDeleteMyQuoteTemplateByIdMutation,
  useDeleteMyShopAddressByIdMutation,
  useDeleteMyShopAddressServiceByIdMutation,
  useDenyQuoteByIdMutation,
  useInviteEmployeeMutation,
  useUpdateEmployeeRoleByIdMutation,
  useUpdateMyCalendarEventMutation,
  useUpdateMyDevicesTokenMutation,
  useUpdateMyMediaMutation,
  useUpdateMyNotificationsReadMutation,
  useUpdateMyProfileMutation,
  useUpdateMyQuoteByIdMutation,
  useUpdateMyQuoteTemplateMutation,
  useUpdateMyShopAddressByIdMutation,
  useUpdateMyShopAddressServiceByIdMutation,
  useUpdateMyShopNameAndBioMutation,
  useUpdateSubscriptionMutation,
  useGetAllMyCustomersQuery,
  useLazyGetAllMyCustomersQuery,
  useGetAllMyEmployeesQuery,
  useLazyGetAllMyEmployeesQuery,
  useGetAllMyShopAddressServicesQuery,
  useLazyGetAllMyShopAddressServicesQuery,
  useGetAllMyShopAddressesQuery,
  useLazyGetAllMyShopAddressesQuery,
  useGetMyCalendarEventDateRangeQuery,
  useLazyGetMyCalendarEventDateRangeQuery,
  useGetMyEmployeesQuery,
  useLazyGetMyEmployeesQuery,
  useGetMyInvitesQuery,
  useLazyGetMyInvitesQuery,
  useGetMyInvoicesQuery,
  useLazyGetMyInvoicesQuery,
  useGetMyNotificationsQuery,
  useLazyGetMyNotificationsQuery,
  useGetMyProfileQuery,
  useLazyGetMyProfileQuery,
  useGetMyProfileDetailQuery,
  useLazyGetMyProfileDetailQuery,
  useGetMyQuoteTemplatesQuery,
  useLazyGetMyQuoteTemplatesQuery,
  useGetMyQuotesQuery,
  useLazyGetMyQuotesQuery,
  useGetMyShopQuery,
  useLazyGetMyShopQuery,
  useGetMyShopAddressServicesQuery,
  useLazyGetMyShopAddressServicesQuery,
  useGetMyShopAddressesQuery,
  useLazyGetMyShopAddressesQuery,
  useGetSubscriptionProductsQuery,
  useLazyGetSubscriptionProductsQuery
} = injectedRtkApi;
